import request from '@/utils/request'


// 查询提现记录列表
export function listUser(query) {
  return request({
    url: '/biz/userWithdraw/list',
    method: 'get',
    params: query
  })
}

// 查询提现记录分页
export function pageUser(query) {
  return request({
    url: '/biz/userWithdraw/getUserWithdrawAuditPage',
    method: 'get',
    params: query
  })
}

// 查询提现记录详细
export function getUser(data) {
  return request({
    url: '/biz/userWithdraw/detail',
    method: 'get',
    params: data
  })
}

// 新增提现记录
export function addUser(data) {
  return request({
    url: '/biz/userWithdraw/add',
    method: 'post',
    data: data
  })
}

// 修改提现记录
export function updateUser(data) {
  return request({
    url: '/biz/userWithdraw/edit',
    method: 'post',
    data: data
  })
}
// 用户提现审核
export function auditWithdraw(data) {
  return request({
    url: '/biz/userWithdraw/auditWithdraw',
    method: 'post',
    data: data
  })
}

// 删除提现记录
export function delUser(data) {
  return request({
    url: '/biz/userWithdraw/delete',
    method: 'post',
    data: data
  })
}
